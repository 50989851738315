import React, {useEffect, useState} from "react";
import {requireAuth} from "../../shared/services/auth.service";
import {Loader} from "@googlemaps/js-api-loader";
import {logError} from "../../shared/services/logger.service";
import {enqueueSnackbar} from "notistack";
import {useNavigate, useParams} from "react-router-dom";
import {LatLngDto} from "../../shared/dtos/estimate-drag-path.dto";
import {Modal} from "@mui/material";
import './AppointmentMapComponent.css';
import {MarkerInfo} from "../../shared/misc/marker-info.interface";
import MapImageIcon from "../../shared/components/MapImageIcon";
import locationIconBlue from '../../assets/location_pin_blue.png';
import {syncMarkers} from "../../shared/services/maps-helper.service";
import {AppointmentSummaryDto} from "../appointment/dto/appointment.dto";
import {getAppointments} from "../appointment/appointment.service";
import AppointmentDetailComponent from "../appointment/AppointmentDetailComponent";


const DEFAULT_ZOOM = 12;
export interface AppointmentMapComponentProps {
  readonly?: boolean;
  initialZoom?: number;
}

const AppointmentMapComponent = (props: AppointmentMapComponentProps) => {
  const navigate = useNavigate();

  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [userLocation, setUserLocation] = useState({lat: 0, lng: 0})

  const [selectedAppointment, setSelectedAppointment] = useState<AppointmentSummaryDto | {} | null>(null);

  const [appointments, setAppointments] = useState<AppointmentSummaryDto[]>([]);
  const [changedAppointments, setChangedAppointments] = useState<AppointmentSummaryDto[]>([]);
  const [appointmentMarkers, setAppointmentMarkers] = useState<MarkerInfo[]>([]);

  const getUserLocationAndUpdate = () => {
    return new Promise<LatLngDto>((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        pos => {
          const newLocation = {lat: pos.coords.latitude, lng: pos.coords.longitude};
          setUserLocation(newLocation);
          resolve(newLocation);
        },
        error => {
          enqueueSnackbar('Error fetching location', {variant: 'error', autoHideDuration: 5000})
          logError("Error getting user's location", {error}, new Error(error.message))
          reject(error);
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0
        });
    });
  }

  useEffect(() => {
    const loader = new Loader({
      apiKey: "AIzaSyBLw50vYSozTEviDIpu94K1KuwM4X_hDUM",
      version: "beta",
    });

    requireAuth(navigate)
      .then(() => getUserLocationAndUpdate())
      .then(retrievedLocation => {
        loader.importLibrary('core').then(async () => {
          const { Map } = await loader.importLibrary("maps") as google.maps.MapsLibrary;
          await Promise.all([
            loader.importLibrary('marker'),
            loader.importLibrary('geometry'),
          ]);
          const appointments = await getAppointments(navigate);
          setAppointments(appointments);
          setChangedAppointments(appointments);

          const map = new Map(document.getElementById("map") as HTMLElement, {
            center: retrievedLocation,
            zoom: props.initialZoom ?? DEFAULT_ZOOM,
            maxZoom: 22,
            mapTypeId: 'roadmap',
            mapId: '100c664330d4fbca',
            tilt: 0,
            streetViewControl: false,
            zoomControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
            scaleControl: false,
            rotateControl: false,
            clickableIcons: false,
          });
          setMap(map);
        });
      })
      .catch(e => {
        logError('Error fetching appointment map', {}, e)
        enqueueSnackbar(`Error fetching appointment map: ${e}`, {variant: 'error', autoHideDuration: 5000});
      })
  }, []);

  useEffect(() => {
    if(!map || !changedAppointments) return;

    const getIcon = (appointment: AppointmentSummaryDto) => {
      return locationIconBlue;
    }

    syncMarkers(
      document,
      map,
      undefined,
      'appointment',
      changedAppointments.map(ce => ({...ce, lat: ce.estimateLat, lng: ce.estimateLng})),
      appointmentMarkers,
      setAppointmentMarkers,
      appointment => appointment.clientName,
      appointment => (<MapImageIcon src={getIcon(appointment)} size={64}/>),
      (appointment) => props.readonly ? () => {} : setSelectedAppointment(appointment),
      (appointment, event) => {},
      { gmpDraggable: false })
  }, [map, changedAppointments]);

  const handleAppointmentSaved = (appointment: AppointmentSummaryDto) => {
    setAppointments(previousAppointments => [...previousAppointments.filter(a => a.id !== appointment.id), appointment]);
    setChangedAppointments(previousAppointments => [...previousAppointments.filter(a => a.id !== appointment.id), appointment]);
  }

  const handleAppointmentDeleted = (appointmentId: number) => {
    setAppointments(previousAppointments => previousAppointments.filter(a => a.id !== appointmentId));
    setChangedAppointments(previousAppointments => previousAppointments.filter(a => a.id !== appointmentId));
  }

  const handleAppointmentDetailClose = () => {
    setSelectedAppointment(null);
  }

  return (
    <div className="full-page">
      <div id="map"></div>
      <div className="full-page control-overlay">
        <Modal
          open={selectedAppointment != null}
          onClose={handleAppointmentDetailClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <AppointmentDetailComponent
            appointmentId={(selectedAppointment as AppointmentSummaryDto)?.id}
            onClose={handleAppointmentDetailClose}
            onSave={handleAppointmentSaved}
            onAppointmentDelete={handleAppointmentDeleted} />
        </Modal>
        <div className="full-page controls-container">
          <div className="bottom-central-controls">
            <div className="bottom-central-controls-column">
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AppointmentMapComponent;