// import { useEffect } from 'react';
// import * as Cesium from 'cesium';

const GoogleEarthPage = () => {
  // (window as any).CESIUM_BASE_URL = '/cesium';
  //
  // useEffect(() => {
  //   const viewer = new Cesium.Viewer('cesiumContainer', {
  //     baseLayerPicker: false,  // Disable base layer picker
  //     requestRenderMode: true, // Efficient rendering mode
  //   });
  //
  // }, []);

  return <div id="cesiumContainer" style={{ width: '100%', height: '100%' }}></div>;
};

export default GoogleEarthPage;
