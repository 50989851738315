import React, {useEffect, useState} from "react";
import {getApiKeys, requireAuth} from "../../shared/services/auth.service";
import {Loader} from "@googlemaps/js-api-loader";
import {logError} from "../../shared/services/logger.service";
import {enqueueSnackbar} from "notistack";
import {useNavigate, useParams} from "react-router-dom";
import {LatLngDto} from "../../shared/dtos/estimate-drag-path.dto";
import {CircularProgress, Fab, Modal, SpeedDial, SpeedDialAction} from "@mui/material";
import {Add as AddIcon, Fence, LocalShipping, LocationSearching, MyLocation, Park, TurnSlightRight} from "@mui/icons-material";
import './AppointmentMapPage.css';
import {MarkerInfo} from "../../shared/misc/marker-info.interface";
import MapImageIcon from "../../shared/components/MapImageIcon";
import locationIconBlack from '../../assets/location_pin_black.png';
import locationIconBlue from '../../assets/location_pin_blue.png';
import locationIconGreen from '../../assets/location_pin_green.png';
import {syncMarkers} from "../../shared/services/maps-helper.service";
import {useToolbar} from "../../components/ToolbarContext";
import {AppointmentSummaryDto} from "../appointment/dto/appointment.dto";
import {getAppointments} from "../appointment/appointment.service";
import AppointmentDetailComponent from "../appointment/AppointmentDetailComponent";
import AppointmentMapComponent from "./AppointmentMapComponent";

const AppointmentMapPage = () => {
  const toolbarContext = useToolbar();

  useEffect(() => {
    toolbarContext.initToolbar('AppointmentMapPage', 'Appointments Map');
  }, []);

  return (
    <div className="full-page">
      <AppointmentMapComponent/>
    </div>
  );
}

export default AppointmentMapPage;