import {NavigateFunction} from "react-router-dom";
import {getUserToken} from "../../shared/services/auth.service";
import {CustomerFacingReceiptDto} from "./dtos/customer-facing-receipt.dto";

export async function getCustomerFacingReceipt(accessToken: string, receiptId: number): Promise<CustomerFacingReceiptDto> {
  const invoice = await fetch(
    `${process.env.REACT_APP_ACCUSITE_API_BASE_URL}/api/v1/receipts/external/${receiptId}`,
    {headers: {Authorization: `Bearer ${accessToken}`}})
    .then(async response => {
      if (!response.ok) {
        throw new Error(`Failed to fetch receipt: ${response.statusText}`);
      }
      return await response.json() as CustomerFacingReceiptDto
    });
  return invoice;
}

export async function emailReceiptToCustomer(navigate: NavigateFunction, receiptId: number): Promise<void> {
  const token = await getUserToken(navigate)
  await fetch(
    `${process.env.REACT_APP_ACCUSITE_API_BASE_URL}/api/v1/receipts/${receiptId}/send-to-customer`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }).then(async response => {
      if (!response.ok) {
        throw new Error(`Failed to email receipt to customer: ${response.statusText}`);
      }
    });
}