import {EstimateDto, EstimateWithClientAndCostDto} from "../../shared/dtos/estimate.dto";
import {AddEstimateDto} from "../../shared/dtos/add-estimate.dto";
import {EstimateWithComponentsDto} from "../../shared/dtos/estimate-with-components.dto";
import {getUserToken} from "../../shared/services/auth.service";
import {NavigateFunction} from "react-router-dom";
import {UpdateEstimateDto} from "../../shared/dtos/update-estimate.dto";
import {CustomerFacingEstimateDto} from "../../shared/dtos/customer-facing-estimate-data.dto";

export async function getEstimate(navigate: NavigateFunction, estimateId: number): Promise<EstimateWithComponentsDto> {
  const token = await getUserToken(navigate)
  const estimate = await fetch(
    `${process.env.REACT_APP_ACCUSITE_API_BASE_URL}/api/v1/estimates/${estimateId}`,
    {headers: {Authorization: `Bearer ${token}`}}).then(async response => {
      if (!response.ok) {
        throw new Error(`Failed to fetch estimate: ${response.statusText}`);
      }
      return await response.json() as EstimateWithComponentsDto
  });
  return estimate;
}

export async function getEstimates(navigate: NavigateFunction): Promise<EstimateDto[]> {
  const token = await getUserToken(navigate)
  const estimates = await fetch(
    `${process.env.REACT_APP_ACCUSITE_API_BASE_URL}/api/v1/estimates`,
    {headers: {Authorization: `Bearer ${token}`}})
    .then(async response => {
      if (!response.ok) {
        throw new Error(`Failed to fetch estimates: ${response.statusText}`);
      }
      return await response.json() as EstimateDto[]
  });
  return estimates;
}

export async function getExtendedEstimates(navigate: NavigateFunction): Promise<EstimateWithClientAndCostDto[]> {
  const token = await getUserToken(navigate)
  const estimates = await fetch(
    `${process.env.REACT_APP_ACCUSITE_API_BASE_URL}/api/v1/estimates/extended`,
    {headers: {Authorization: `Bearer ${token}`}})
    .then(async response => {
      if (!response.ok) {
        throw new Error(`Failed to fetch extended estimates: ${response.statusText}`);
      }
      return await response.json() as EstimateWithClientAndCostDto[]
    });
  return estimates;
}

export async function createEstimate(navigate: NavigateFunction, estimate: AddEstimateDto): Promise<EstimateWithComponentsDto> {
  const token = await getUserToken(navigate)
  const createdEstimate = await fetch(
    `${process.env.REACT_APP_ACCUSITE_API_BASE_URL}/api/v1/estimates`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(estimate)
    }).then(async response => {
      if (!response.ok) {
        throw new Error(`Failed to create estimate: ${response.statusText}`);
      }
      return await response.json() as EstimateWithComponentsDto
  });
  return createdEstimate;
}

export async function updateEstimate(navigate: NavigateFunction, estimateId: number, estimate: UpdateEstimateDto): Promise<EstimateWithComponentsDto> {
  const token = await getUserToken(navigate)
  const updatedEstimate = await fetch(
    `${process.env.REACT_APP_ACCUSITE_API_BASE_URL}/api/v1/estimates/${estimateId}`,
    {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(estimate)
    }).then(async response => {
      if (!response.ok) {
        throw new Error(`Failed to update estimate: ${response.statusText}`);
      }
      return await response.json() as EstimateWithComponentsDto
  });
  return updatedEstimate;
}

export async function emailEstimateToCustomer(navigate: NavigateFunction, estimateId: number): Promise<EstimateWithComponentsDto> {
  const token = await getUserToken(navigate)
  const sentEstimate = await fetch(
    `${process.env.REACT_APP_ACCUSITE_API_BASE_URL}/api/v1/estimates/${estimateId}/send-to-customer`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }).then(async response => {
      if (!response.ok) {
        throw new Error(`Failed to email estimate: ${response.statusText}`);
      }
      return await response.json() as EstimateWithComponentsDto
    })

    return sentEstimate;
}

export async function getCustomerFacingEstimate(accessToken: string, estimateId: number): Promise<CustomerFacingEstimateDto> {
  const estimate = await fetch(
    `${process.env.REACT_APP_ACCUSITE_API_BASE_URL}/api/v1/estimates/external/${estimateId}`,
    {headers: {Authorization: `Bearer ${accessToken}`}})
    .then(async response => {
      if (!response.ok) {
        throw new Error(`Failed to fetch customer facing estimate: ${response.statusText}`);
      }
      return await response.json() as CustomerFacingEstimateDto
    });
  return estimate;
}

export async function customerApproveEstimate(accessToken: string, estimateId: number, signatureFile: File): Promise<CustomerFacingEstimateDto> {
  if(!signatureFile) throw new Error('No signature provided')

  const formData = new FormData();
  formData.append('file', signatureFile);

  const estimate = await fetch(
    `${process.env.REACT_APP_ACCUSITE_API_BASE_URL}/api/v1/estimates/external/${estimateId}/approve`,
    {
      method: 'POST',
      body: formData,
      headers: {Authorization: `Bearer ${accessToken}`}
    }).then(async response => {
      if (!response.ok) {
        throw new Error(`Failed to approve estimate: ${response.statusText}`);
      }
      return await response.json() as CustomerFacingEstimateDto
    });
  return estimate;
}
