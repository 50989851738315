import {NavigateFunction} from "react-router-dom";
import {getUserToken} from "../../shared/services/auth.service";
import {AddCompanyIntegrationDto, CompanyIntegrationDto, UpdateCompanyIntegrationDto} from "./dtos/integration.dto";

export async function getCompanyIntegrations(navigate: NavigateFunction): Promise<CompanyIntegrationDto[]> {
  const token = await getUserToken(navigate)
  const results = await fetch(
    `${process.env.REACT_APP_ACCUSITE_API_BASE_URL}/api/v1/integrations/company`,
    {headers: {Authorization: `Bearer ${token}`}})
    .then(async response => {
      if (!response.ok) {
        throw new Error(`Failed to fetch company integrations: ${response.statusText}`);
      }
      return await response.json() as CompanyIntegrationDto[]
    });
  return results;
}

export async function getCompanyIntegration(navigate: NavigateFunction, integrationId: number): Promise<CompanyIntegrationDto> {
  const token = await getUserToken(navigate)
  const result = await fetch(
    `${process.env.REACT_APP_ACCUSITE_API_BASE_URL}/api/v1/integrations/company/${integrationId}`,
    {headers: {Authorization: `Bearer ${token}`}})
    .then(async response => {
      if (!response.ok) {
        throw new Error(`Failed to fetch company integration: ${response.statusText}`);
      }
      return await response.json() as CompanyIntegrationDto
    });
  return result;
}

export async function addCompanyIntegration(navigate: NavigateFunction, integration: AddCompanyIntegrationDto): Promise<CompanyIntegrationDto> {
  const token = await getUserToken(navigate)
  const result = await fetch(
    `${process.env.REACT_APP_ACCUSITE_API_BASE_URL}/api/v1/integrations/company`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(integration)
    }).then(async response => {
      if (!response.ok) {
        throw new Error(`Failed to add company integration: ${response.statusText}`);
      }
      return await response.json() as CompanyIntegrationDto
    });
  return result;
}

export async function updateCompanyIntegration(navigate: NavigateFunction, integrationId: number, integration: UpdateCompanyIntegrationDto): Promise<CompanyIntegrationDto> {
  const token = await getUserToken(navigate)
  const response = await fetch(
    `${process.env.REACT_APP_ACCUSITE_API_BASE_URL}/api/v1/integrations/company/${integrationId}`,
    {
      method: 'PATCH',
      body: JSON.stringify(integration),
      headers:
        {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
    })
    .then(async r => r.ok ? (await r.json()) as CompanyIntegrationDto : Promise.reject(r));

  return response;
}

export async function deleteCompanyIntegration(navigate: NavigateFunction, integrationId: number): Promise<void> {
  const token = await getUserToken(navigate)
  await fetch(
    `${process.env.REACT_APP_ACCUSITE_API_BASE_URL}/api/v1/integrations/company/${integrationId}`,
    {
      method: 'DELETE',
      headers:
        {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
    })
    .then(async response => {
      if (!response.ok) {
        throw new Error(`Failed to delete company integration: ${response.statusText}`);
      }
    });
}