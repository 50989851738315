import {EstimateDto, EstimateWithClientAndCostDto} from "../../shared/dtos/estimate.dto";
import {AddEstimateDto} from "../../shared/dtos/add-estimate.dto";
import {EstimateWithComponentsDto} from "../../shared/dtos/estimate-with-components.dto";
import {getUserToken} from "../../shared/services/auth.service";
import {NavigateFunction} from "react-router-dom";
import {UpdateEstimateDto} from "../../shared/dtos/update-estimate.dto";

export async function getWorkOrders(navigate: NavigateFunction, filters?: string): Promise<EstimateWithClientAndCostDto[]> {
  const token = await getUserToken(navigate)
  const filterString = filters ? `?filters=${filters}` : '';
  const workOrders = await fetch(
    `${process.env.REACT_APP_ACCUSITE_API_BASE_URL}/api/v1/work-orders${filterString}`,
    {headers: {Authorization: `Bearer ${token}`}})
    .then(async response => {
      if (!response.ok) {
        throw new Error(`Failed to fetch work orders: ${response.statusText}`);
      }
      return await response.json() as EstimateWithClientAndCostDto[]
    });
  return workOrders;
}

export async function createInvoiceAndSend(navigate: NavigateFunction, workOrderId: number): Promise<EstimateWithClientAndCostDto> {
  const token = await getUserToken(navigate)
  const createdInvoice = await fetch(
    `${process.env.REACT_APP_ACCUSITE_API_BASE_URL}/api/v1/work-orders/${workOrderId}/create-invoice-and-send`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }).then(async response => {
      if (!response.ok) {
        throw new Error(`Failed to create invoice: ${response.statusText}`);
      }
      return await response.json() as EstimateWithClientAndCostDto
    });
  return createdInvoice;
}


