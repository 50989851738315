import {NavigateFunction} from "react-router-dom";
import {AddEstimateTreeDto, EstimateTreeDto, UpdateEstimateTreeDto} from "../../shared/dtos/estimate-tree.dto";
import {getUserToken} from "../../shared/services/auth.service";
import {EstimateGateDetailsDto, EstimateGateDto, UpdateEstimateGateDto} from "../../shared/dtos/estimate-gate.dto";

export async function getGate(navigate: NavigateFunction, estimateId: number, gateId: number): Promise<EstimateGateDetailsDto> {
  const token = await getUserToken(navigate)
  const gate = await fetch(
    `${process.env.REACT_APP_ACCUSITE_API_BASE_URL}/api/v1/estimates/${estimateId}/gates/${gateId}`,
    {headers: {Authorization: `Bearer ${token}`}})
    .then(async r => r.ok ? (await r.json()) as EstimateGateDetailsDto : Promise.reject(r));
  return gate;
}

export async function createGate(navigate: NavigateFunction, estimateId: number, tree: AddEstimateTreeDto) {
  const token = await getUserToken(navigate)
  const createdTree = await fetch(
    `${process.env.REACT_APP_ACCUSITE_API_BASE_URL}/api/v1/estimates/${estimateId}/gates`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(tree),
    }).then(async r => {
      if (!r.ok) {
        throw new Error(`Failed to create estimate: ${r.statusText}`);
      }
      return (await r.json()) as EstimateGateDetailsDto
  });
  return createdTree;
}

export async function updateGate(navigate: NavigateFunction, estimateId: number, gateId: number, tree: UpdateEstimateGateDto): Promise<EstimateGateDetailsDto> {
  const token = await getUserToken(navigate)
  const response = await fetch(
    `${process.env.REACT_APP_ACCUSITE_API_BASE_URL}/api/v1/estimates/${estimateId}/gates/${gateId}`,
    {
      method: 'PATCH',
      body: JSON.stringify(tree),
      headers:
        {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
    })
    .then(async r => r.ok ? (await r.json()) as EstimateGateDetailsDto : Promise.reject(r));

  return response;
}

export async function deleteGate(navigate: NavigateFunction, estimateId: number, gateId: number): Promise<void> {
  const token = await getUserToken(navigate)
  const response = await fetch(
    `${process.env.REACT_APP_ACCUSITE_API_BASE_URL}/api/v1/estimates/${estimateId}/gates/${gateId}`,
    {
      method: 'DELETE',
      headers:
        {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
    })
  if(!response.ok) {
    throw new Error(`Failed to delete gate: ${response.statusText}`);
  }
}