import {EstimateDto, EstimateWithClientAndCostDto} from "../../shared/dtos/estimate.dto";
import {AddEstimateDto} from "../../shared/dtos/add-estimate.dto";
import {EstimateWithComponentsDto} from "../../shared/dtos/estimate-with-components.dto";
import {getUserToken} from "../../shared/services/auth.service";
import {NavigateFunction} from "react-router-dom";
import {UpdateEstimateDto} from "../../shared/dtos/update-estimate.dto";
import {CustomerFacingInvoiceDto} from "./dtos/customer-facing-invoice.dto";

export async function getInvoices(navigate: NavigateFunction): Promise<EstimateWithClientAndCostDto[]> {
  const token = await getUserToken(navigate)
  const invoices = await fetch(
    `${process.env.REACT_APP_ACCUSITE_API_BASE_URL}/api/v1/invoices`,
    {headers: {Authorization: `Bearer ${token}`}})
    .then(async response => {
      if (!response.ok) {
        throw new Error(`Failed to fetch invoices: ${response.statusText}`);
      }
      return await response.json() as EstimateWithClientAndCostDto[]
    });
  return invoices;
}

export async function getCustomerFacingInvoice(accessToken: string, invoiceId: number): Promise<CustomerFacingInvoiceDto> {
  const invoice = await fetch(
    `${process.env.REACT_APP_ACCUSITE_API_BASE_URL}/api/v1/invoices/external/${invoiceId}`,
    {headers: {Authorization: `Bearer ${accessToken}`}})
    .then(async response => {
      if (!response.ok) {
        throw new Error(`Failed to fetch invoice: ${response.statusText}`);
      }
      return await response.json() as CustomerFacingInvoiceDto
    });
  return invoice;
}

export async function emailInvoiceToCustomer(navigate: NavigateFunction, invoiceId: number): Promise<void> {
  const token = await getUserToken(navigate)
  await fetch(
    `${process.env.REACT_APP_ACCUSITE_API_BASE_URL}/api/v1/invoices/${invoiceId}/send-to-customer`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }).then(async response => {
      if (!response.ok) {
        throw new Error(`Failed to email invoice to customer: ${response.statusText}`);
      }
    });
}


