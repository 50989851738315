import {NavigateFunction} from "react-router-dom";
import {getUserToken} from "../../shared/services/auth.service";
import {AppointmentDto, AppointmentSummaryDto, AppointmentDetailDto, CreateAppointmentDto, UpdateAppointmentDto} from "./dto/appointment.dto";

export async function getAppointments(navigate: NavigateFunction): Promise<AppointmentSummaryDto[]> {
  const token = await getUserToken(navigate)
  const appointments = await fetch(
    `${process.env.REACT_APP_ACCUSITE_API_BASE_URL}/api/v1/appointments`,
    {headers: {Authorization: `Bearer ${token}`}})
    .then(async response => {
      if (!response.ok) {
        throw new Error(`Failed to fetch appointments: ${response.statusText}`);
      }
      return await response.json() as AppointmentSummaryDto[]
    });
  return appointments;
}

export async function getAppointment(navigate: NavigateFunction, appointmentId: number): Promise<AppointmentSummaryDto> {
  const token = await getUserToken(navigate)
  const appointment = await fetch(
    `${process.env.REACT_APP_ACCUSITE_API_BASE_URL}/api/v1/appointments/${appointmentId}`,
    {headers: {Authorization: `Bearer ${token}`}})
    .then(async response => {
      if (!response.ok) {
        throw new Error(`Failed to fetch appointment: ${response.statusText}`);
      }
      return await response.json() as AppointmentSummaryDto
    });
  return appointment;
}

export async function createAppointment(navigate: NavigateFunction, appointment: CreateAppointmentDto): Promise<AppointmentSummaryDto> {
  const token = await getUserToken(navigate)
  const createdAppointment = await fetch(
    `${process.env.REACT_APP_ACCUSITE_API_BASE_URL}/api/v1/appointments`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(appointment)
    }).then(async response => {
      if (!response.ok) {
        throw new Error(`Failed to create appointment: ${response.statusText}`);
      }
      return await response.json() as AppointmentSummaryDto
  });
  return createdAppointment;
}

export async function updateAppointment(navigate: NavigateFunction, appointmentId: number, appointment: UpdateAppointmentDto): Promise<AppointmentSummaryDto> {
  const token = await getUserToken(navigate)
  const response = await fetch(
    `${process.env.REACT_APP_ACCUSITE_API_BASE_URL}/api/v1/appointments/${appointmentId}`,
    {
      method: 'PATCH',
      body: JSON.stringify(appointment),
      headers:
        {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
    })
    .then(async r => r.ok ? (await r.json()) as AppointmentSummaryDto : Promise.reject(r));
  return response;
}

export async function deleteAppointment(navigate: NavigateFunction, appointmentId: number): Promise<void> {
  const token = await getUserToken(navigate)
  const response = await fetch(
    `${process.env.REACT_APP_ACCUSITE_API_BASE_URL}/api/v1/appointments/${appointmentId}`,
    {
      method: 'DELETE',
      headers:
        {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
    });
  if(!response.ok) {
    throw new Error(`Failed to delete appointment: ${response.statusText}`);
  }
}