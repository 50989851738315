import {NavigateFunction} from "react-router-dom";
import {getUserToken} from "../../shared/services/auth.service";
import {AddClientDto, ClientDto, UpdateClientDto} from "./dtos/client.dto";

export async function getClients(navigate: NavigateFunction, name?: string): Promise<ClientDto[]> {
  const token = await getUserToken(navigate)
  const nameFilter = name ? `?name=${name}` : '';
  const clients = await fetch(
    `${process.env.REACT_APP_ACCUSITE_API_BASE_URL}/api/v1/clients${nameFilter}`,
    {headers: {Authorization: `Bearer ${token}`}})
    .then(async response => {
      if (!response.ok) {
        throw new Error(`Failed to fetch clients: ${response.statusText}`);
      }
      return await response.json() as ClientDto[]
    });
  return clients;
}

export async function getClient(navigate: NavigateFunction, clientId: number): Promise<ClientDto> {
  const token = await getUserToken(navigate)
  const client = await fetch(
    `${process.env.REACT_APP_ACCUSITE_API_BASE_URL}/api/v1/clients/${clientId}`,
    {headers: {Authorization: `Bearer ${token}`}})
    .then(async response => {
      if (!response.ok) {
        throw new Error(`Failed to fetch client: ${response.statusText}`);
      }
      return await response.json() as ClientDto
    });
  return client;
}

export async function createClient(navigate: NavigateFunction, client: AddClientDto): Promise<ClientDto> {
  const token = await getUserToken(navigate)
  const createdClient = await fetch(
    `${process.env.REACT_APP_ACCUSITE_API_BASE_URL}/api/v1/clients`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(client)
    })    .then(async response => {
    if (!response.ok) {
      throw new Error(`Failed to create client: ${response.statusText}`);
    }
    return await response.json() as ClientDto
  });
  return createdClient;
}

export async function updateClient(navigate: NavigateFunction, clientId: number, client: UpdateClientDto): Promise<ClientDto> {
  const token = await getUserToken(navigate)
  const response = await fetch(
    `${process.env.REACT_APP_ACCUSITE_API_BASE_URL}/api/v1/clients/${clientId}`,
    {
      method: 'PATCH',
      body: JSON.stringify(client),
      headers:
        {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
    }).then(async response => {
      if (!response.ok) {
        throw new Error(`Failed to update client: ${response.statusText}`);
      }
      return await response.json() as ClientDto
    });

  return response;
}

export async function deleteClient(navigate: NavigateFunction, clientId: number): Promise<void> {
  const token = await getUserToken(navigate)
  const response = await fetch(
    `${process.env.REACT_APP_ACCUSITE_API_BASE_URL}/api/v1/clients/${clientId}`,
    {
      method: 'DELETE',
      headers:
        {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
    });
  if(!response.ok) {
    throw new Error(`Failed to delete client: ${response.statusText}`);
  }
}