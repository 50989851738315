import {NavigateFunction} from "react-router-dom";
import {getUserToken} from "../../shared/services/auth.service";
import {
  EstimateTimeDto,
  StartNewEstimateTimeDto,
  UpdateEstimateTimeDto,
} from "../../shared/dtos/estimate-time.dto";

export async function getEstimateTime(navigate: NavigateFunction, estimateId: number): Promise<EstimateTimeDto | null> {
  const token = await getUserToken(navigate)
  const createdTime = await fetch(
    `${process.env.REACT_APP_ACCUSITE_API_BASE_URL}/api/v1/estimates/${estimateId}/times`,
    {headers: {Authorization: `Bearer ${token}`}})
    .then(async r => {
      if(r.status == 204) {
        return null;
      }
      return r.ok ? (await r.json()) as EstimateTimeDto : Promise.reject(r);
    })
  return createdTime;
}

export async function startNewEstimateTime(navigate: NavigateFunction, estimateId: number, estimateTime: StartNewEstimateTimeDto) {
  const token = await getUserToken(navigate)
  const createdTime = await fetch(
    `${process.env.REACT_APP_ACCUSITE_API_BASE_URL}/api/v1/estimates/${estimateId}/times`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(estimateTime),
    }).then(async r => {
      if (!r.ok) {
        throw new Error(`Failed to start new estimate time: ${r.statusText}`);
      }
      return (await r.json()) as EstimateTimeDto
    });
  return createdTime;
}

export async function updateEstimateTime(navigate: NavigateFunction, estimateId: number, estimateTimeId: number, update: UpdateEstimateTimeDto): Promise<EstimateTimeDto> {
  const token = await getUserToken(navigate)
  const response = await fetch(
    `${process.env.REACT_APP_ACCUSITE_API_BASE_URL}/api/v1/estimates/${estimateId}/times/${estimateTimeId}`,
    {
      method: 'PATCH',
      body: JSON.stringify(update),
      headers:
        {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
    })
    .then(async r => r.ok ? (await r.json()) as EstimateTimeDto : Promise.reject(r));

  return response;
}
